import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ "@/views/home")    // 首页重置到home页，为预渲染重置到home
  },
  {
    path: "/home",
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ "@/views/home"),
    meta: {
      keepAlive: true, // 组件需要缓存
    }
  },
  {
    path: "/about",
    name: "about",
    component: () => import(/* webpackChunkName: "about" */ "@/views/about"),
    meta: {
      keepAlive: true, // 组件需要缓存
    }
  },
  {
    path: "/appDown",
    name: "appDown",
    component: () => import(/* webpackChunkName: "about" */ "@/views/appDown"),
    meta: {
      keepAlive: true, // 组件需要缓存
    }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// router.beforeEach((to, from, next) => {
// 	console.log("全局守卫：", to);
//   next()
// });
export default router;
