import { createApp, onMounted } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import VueLazyLoad from 'vue-lazyload'

import './assets/style.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

const myPlugin = {
	install(app, options) {
        document.dispatchEvent(new Event('render-event'))
	}
}

createApp(App)
.use(router)
.use(createPinia())
.use(VueLazyLoad, {
    preLoad: 1,
    error: require('@/assets/images/error.png'),
    loading: require('@/assets/images/loading.gif'),
    attempt: 2,
})
.use(myPlugin)
.use(ElementPlus)
.mount('#app') 
