<template>
  <Transition name="fade">
    <router-view v-slot="{ Component }">
        <KeepAlive>
            <component :is="Component" :key="route.name" v-if="route.meta.keepAlive" />
        </KeepAlive>
        <component :is="Component" :key="route.name" v-if="!route.meta.keepAlive" />
    </router-view>
  </Transition>
</template>
<script setup>
import { useRoute } from 'vue-router';
const route = useRoute();
</script>

<style>
</style>
